import i18n from "@/core/plugins/vue-i18n";
var i18 = i18n.messages[localStorage.getItem("language")];
import moment from "moment";
import axios from "axios";
moment.locale(localStorage.getItem("language"));
import Cookie from "@/core/services/localstorage.module";
//import axios from "axios";

function SelectBoxFormat() {
	
	var DataCars = this.GetGpCarData();
	var options = [];
	var k = 0;
	for(var i in DataCars){
		if(!options[k]){
			options[k] = {};
			options[k].children = [];
		}
		//options[k] = i;//optionsDataCars[i]
		options[k].id = i;
		options[k].label = "📁"+DataCars[i].name;
		//var F = 0;
		for(var j in DataCars[i]){
			if(DataCars[i][j].vehicle)
				options[k].children.push({label:"🌐"+DataCars[i][j].vehicle,id : DataCars[i][j].id, isNew: true});
		}
		k = k+1;
	}
	
	return options;
}
function GetAllCarGpById(DATA) {
	var mindata = Cookie.GetCookie('mindata');
	var res = [];
	
	for(var t in DATA){
		
		if(typeof(DATA[t])== 'string' && DATA[t][0] == "G"){
			var variables = DATA[t].split("_");
			for(var i in mindata){
				if(mindata[i].id_groupe == variables[1]){
					res.push(mindata[i].id);
				}
			}
		}else{
			res.push(DATA[t]);
		}
	}
	return res

}


function GetGpCarData() {
	
	var mindata = Cookie.GetCookie('mindata');
	var groupe_management = Cookie.GetCookie('groupe_management');
	
	var itemsData = {}; 
	itemsData[0] ={}; 
	//var mindataShow = [];
	var mindataShowGp = {};
	//var key = [];
	var i;
	//console.log(mindata);
	for (i in mindata){
		if(mindata[i].id_groupe == null || mindata[i].id_groupe == 'null'){
			mindata[i].id_groupe = 0;
		}
		if(itemsData.length==0){
			itemsData[0] = {vehicle:mindata[i].name,id:mindata[i].id};
		}else{
			itemsData[mindata[i].id] = {vehicle:mindata[i].name,id:mindata[i].id};
		}
		if(!mindataShowGp["G_"+mindata[i].id_groupe]){
			mindataShowGp["G_"+mindata[i].id_groupe] = {};
			mindataShowGp["G_"+mindata[i].id_groupe].name = mindata[i].id_groupe != 0 ? groupe_management[mindata[i].id_groupe].name : "";
			//key[mindata[i].id_groupe] = 0;
		}
		mindataShowGp["G_"+mindata[i].id_groupe][mindata[i].id] = {vehicle:mindata[i].name,id:mindata[i].id};
		
		
	}
	//console.log("***********************************************");
	//console.log(mindataShowGp);
	return mindataShowGp;
}

async function GetData(datai) {	
	//datai = datai[0];
	var data = new Object();

	data["id"] = datai._id;
	data["vehicle"] = datai.name;
	data["rtc"] = datai.rtc;
	data["adresse"] = await get_adresse_pos(datai.adresse,datai.gps_latitude,datai.gps_longitude);//(datai.adresse != "" ? datai.adresse : await GetAdresse(data["location"]["lat"],data["location"]["lng"]));
	//data["adresse"] = datai.adresse;

	data["location"] = {};
	data["location"]["lat"] = datai.gps_latitude;
	data["location"]["lng"] = datai.gps_longitude;
	data["speed"] = datai.gps_speed;
	data["speed_text"] = await get_speed(datai.gps_speed);
	data["odometer"] = datai.odometer;
	data["odometer_text"] = await get_odometer(datai.odometer,true);
	data["odometerTrip"] = datai.odometer_trip;
	data["heading"] = datai.gps_heading;
	data["altitude"] = datai.gps_altitude;
	data["fuelLevel"] = datai.fuel_level;
	data["param1"] = datai.param1;
	data["param1_text"] = i18.PARAMS[datai.param1];
	data["gpsStatusInfo"] = datai.gps_status_info;
	data["get_gps_status_info"] = await get_gps_status_info(datai.gps_status_info);
	data["get_color_gpsstatus"] = await get_color_gpsstatus(datai.motion,datai.gps_status_info);
	data["vin"] = await get_vin(datai.obd_vin,datai.properties);
	data["ignition"] = datai.ignition;
	data["ignition_text"] = await get_ignition(datai.ignition);
	data["lastTimeIgnition"] = datai.last_ignition_time;
	data["mainpower"] = datai.mainpower;
	data["last_mainpower_time"] = datai.last_mainpower_time;
	data["main_power_voltage"] = datai.main_power_voltage;
	data["motion"] = datai.motion;
	data["motion_text"] = await get_motion(datai.motion);
	data["lastTimeMotion"] = datai.last_motion_time;
	//
	data["color_ignition"] =  await get_color_ignition(datai.ignition);
	data["color_motion"] =  await get_color_motion(datai.motion);
	data["range_last_ignition"] =  await get_range_last_ignition(datai.last_ignition_time,datai.ignition);
	
	
	data["fuelLevel_text"] =  await get_fuel_level_txt(datai.fuelLevel,datai.properties);
	data["immatriculation"] =  await get_immatriculation(datai.properties);
	data["img_constructeur"] =  await get_img_constructeur(datai.properties);
	data["gps_number_sat"] =  datai.gps_number_sat;
	data["J1939_Acceleration_pedal_position"] =  datai.J1939_Acceleration_pedal_position && datai.J1939_Acceleration_pedal_position != ""? datai.J1939_Acceleration_pedal_position : "-";
	data["J1939_Fuel_level"] =  datai.J1939_Fuel_level && datai.J1939_Fuel_level != ""? datai.J1939_Fuel_level : "-";
	data["J1939_Engine_Speed"] =  datai.J1939_Engine_Speed && datai.J1939_Engine_Speed != ""? datai.J1939_Engine_Speed : "-";
	//////////////////////////////////////////device
	data["mdmid"] = datai.mdmid;//013799001703471 // 013799001703471
	data["img"] = "/media/tlmtk/no-picture.png";
	/*var test = datai.mdmid.substr(0, 5);
	var testX = datai.mdmid.substr(0, 2);
	if(test == "01379"){
		data["img"] = "/media/tlmtk/mt3060.png";
		data["devise"] = "Novatel Wireless MT3060";
		data["obd_ignition"] = "OBD"
	}else if(test == "01298"){
		data["img"] = "/media/tlmtk/mt4100.png";
		data["devise"] = "Novatel Wireless MT4000";
		data["obd_ignition"] =  "No OBD";
	}else if(testX == "86"){
		data["img"] = "/media/tlmtk/xirgo-500.jpg";
		data["devise"] = "Xirgo FMS500 StCAN";
		data["obd_ignition"] =  datai.obd_ignition == "true" || datai.obd_ignition == true ? "OBD" : "Cable";
	}*/
	
	data["first_contact"] = datai.ubi.first_contact_ubi
	//main_power_voltage
	return data;
}

async function get_adresse_pos(adresse,lat,lon) {
	axios.defaults.withCredentials = true;
	if(adresse != ""){
		return adresse;
	}
		
	return await axios.get("https://map.fleetium.com/nominatim/reverse.php?format=jsonv2&action=reverse&lat="+lat+"&lon="+lon+"&accept-language=fr").then(async (response) => {
			var adresse = "-";
			if(typeof(response['address']) !== "undefined"){
				if(typeof(response['address']['road']) !== "undefined")
					adresse = response['address']['road'];
				if(typeof(response['address']['suburb']) !== "undefined")
					adresse = adresse != "" ? adresse+", "+response['address']['suburb'] : response['address']['suburb'];
				if(typeof(response['address']['neighbourhood']) !== "undefined")
					adresse = adresse != "" ? adresse+", "+response['address']['neighbourhood'] : response['address']['neighbourhood'];
				if(typeof(response['address']['village']) !== "undefined")
					adresse = adresse != "" ? adresse+", "+response['address']['village'] : response['address']['village'];
				if(typeof(response['address']['state_district']) !== "undefined")
					adresse = adresse != "" ? adresse+", "+response['address']['state_district'] : response['address']['state_district'];
				if(typeof(response['address']['state']) !== "undefined")
					adresse = adresse != "" ? adresse+", "+response['address']['state'] : response['address']['state'];
			} 
			return adresse;
		}).catch((error) => {    
			return error;
		});
	
};

async function get_immatriculation(properties){
    if(properties.registration.immatriculation){
        return properties.registration.immatriculation ;
    }else{
        return i18.TEXT_ALL.MSG_NO_IMMATRICULATION;
    }
};
async function get_img_constructeur(properties){
    if(properties.registration.img_constructeur != ""){
        return properties.registration.img_constructeur ;
    }else{
        return false;
    }
};
async function get_fuel_level(is_percentage=true,fuelLevel,properties){
    if(fuelLevel == "-"){
		return '';
	}
	return is_percentage ? fuelLevel : (fuelLevel*properties.technical.reservoir)/100 ;
};
async function get_fuel_level_txt(fuelLevel,properties){
    if(fuelLevel == "-"){
		return '';
	}
	return (fuelLevel && properties.technical.reservoir) ? await get_fuel_level(false,fuelLevel,properties)+" ("+await get_fuel_level(true,fuelLevel,properties)+"%)" : "-"
};
async function get_odometer(odometer,bool_add_unit=false){
    if(odometer == "-"){
		return "-";
	}
	odometer=parseInt(parseInt(odometer)/1000);
    var tabnum=odometer.toString().split('').reverse().join('').match(/\d{1,3}/g);
    if(tabnum){
		odometer=tabnum.join(' ').split('').reverse().join('');
    }
	return odometer+(bool_add_unit ? " "+i18.TEXT_ALL.KM : "");
}

async function get_ignition(ignition){
    if(get_ignition == "-"){
		return i18.PRIVATE_MOD;
	}
	
	return ignition ? i18.MOVING_FOR : i18.PARKING_FOR ;
};
async function get_color_ignition(ignition){
	if(ignition == "-"){
		return "info";
	}
	return ignition ? 'primary' : 'danger' ;
}
async function get_motion(motion){
    return motion ? 'On' : 'Off' ;
}
async function get_speed(speed){
    return parseInt(parseFloat(speed)) +""+ i18.TEXT_ALL.KM_H;
}

async function get_gps_status_info(gps_status_info){
	return gps_status_info !=9 ? true : false;
}


async function  get_color_motion(motion){
	if(motion == "-"){
		return "#FD6A02";
	}
    return motion ? '#1BC5BD' : '#F64E60' ;
}
async function  get_range_last_ignition(lastTimeIgnition,ignition){
	var ignition_text = ignition ? i18.TEXT_ALL.MOVING_FOR : i18.TEXT_ALL.PARKING_FOR
	if(lastTimeIgnition == "-"){//MOVING_FOR     PARKING_FOR
		return "";
	}
	if(moment(lastTimeIgnition) > moment() && moment(lastTimeIgnition).isValid()){
		return ignition_text+" "+moment().fromNow() ;
	}else if(moment(lastTimeIgnition)<=moment() && moment(lastTimeIgnition).isValid()){
		return ignition_text+" "+moment(lastTimeIgnition).fromNow() ;
	}else{
		return "-";
	}
}
async function  get_color_gpsstatus(motion,gps_status_info){
    if(motion == "-"){
		return "#FD6A02";
	}
	return gps_status_info!=9 ? '#1caf9a' :'#Ed1c24'  ;
};
async function  get_vin(obd_vin,properties){
	if(obd_vin){
        return obd_vin ;
    }else if(typeof(properties.registration) && properties.registration.vin){
        return properties.registration.vin;
    }else{
        return i18.NO_VIN;
    }

};
//function global
function GetNameCarsById (id){
	var CarsData = Cookie.GetCookie('mindata');
	for ( var i = 0, l = CarsData.length; i < l; i++) {
		if(parseInt(CarsData[i].id) == parseInt(id)){
			return CarsData[i].name;
		}
	}
}
function  ConvertTimeHeure (time,timeOnly){
	if (time>=86400 && !timeOnly)
	{
		var jour = Math.floor(time/86400);
		var reste = time%86400;
		var heure =  Math.floor(reste/3600);
		
		// puis le nombre d'heures
		reste = reste%3600;
		var minute = Math.floor(reste/60);
		var seconde = reste%60;
		heure =  heure+(jour*24);
		var result = heure+'h '+minute+'m '+Math.floor(seconde)+'s';
	}
	else if (time>=3600)
	{
		heure =  Math.floor(time/3600);
		reste = time%3600;
		minute = Math.floor(reste/60);
		seconde = reste%60;
		result = heure+'h '+minute+'m '+Math.floor(seconde)+'s';
	}
	else if (time<3600 && time>=60)
	{
		minute = Math.floor(time/60);
		seconde = time%60;
		result = minute+'m '+Math.floor(seconde)+'s';
	}
	else if (time < 60) 
	{
		result = time+'s';
	}
	return result;	
}



export default {
	GetData,GetGpCarData,SelectBoxFormat,GetAllCarGpById,ConvertTimeHeure,GetNameCarsById
};

